/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../../Config/config";
import {
  putTempPageIndex,
  updateActualPageIndex,
  updateCouvertures,
  updateDayBorn,
  updateDureeEmprunt,
  updateEmail,
  updateFirstName,
  updateFumeur,
  updateLastName,
  updateMontantEmprunt,
  updateMonthBorn,
  updateOptin,
  updatePhone,
  updatePhoneChecked,
  updateQuotite,
  updateSessionId,
  updateStartedSimulation,
  updateStatutProfessionnel,
  updateTempPageIndex,
  updateTimestamp,
  updateTypePret,
  updateYearBorn,
} from "../../../Store/store";
import Redirect from "../../Redirect";
import Modal from "./Modal";

function continueSimulation(dispatch) {
  dispatch(updateStartedSimulation("true"));
  dispatch(putTempPageIndex());
}

function startNewSimulation(dispatch) {
  fetch(
    backendUrl + "simulations/emprunteur/startSimulation/?newSimulation=true",
    { credentials: "include", crossDomain: true }
  ).then((resp) => {
    resp.json().then((data) => {
      if (data.statut === "success") {
        dispatch(
          updateSessionId(data.sessionId === null ? "" : data.sessionId)
        );
        dispatch(
          updateTimestamp(data.timestamp === null ? "" : data.timestamp)
        );
        dispatch(
          updateMontantEmprunt(
            data.montantEmprunt === null ? "" : data.montantEmprunt
          )
        );
        dispatch(
          updateDureeEmprunt(
            data.dureeEmprunt === null ? "" : data.dureeEmprunt
          )
        );
        dispatch(updateQuotite(data.quotite === null ? "" : data.quotite));
        dispatch(
          updateFirstName(data.firstName === null ? "" : data.firstName)
        );
        dispatch(updateLastName(data.lastName === null ? "" : data.lastName));
        dispatch(updateEmail(data.email === null ? "" : data.email));
        dispatch(updatePhone(data.phone === null ? "" : data.phone));
        dispatch(updatePhoneChecked(data.phoneChecked === null ? "" : data.phoneChecked));
        dispatch(updateDayBorn(data.dayBorn === null ? "" : data.dayBorn));
        dispatch(
          updateMonthBorn(data.monthBorn === null ? "" : data.monthBorn)
        );
        dispatch(updateYearBorn(data.yearBorn === null ? "" : data.yearBorn));
        dispatch(updateTypePret(data.typePret === null ? "" : data.typePret));
        dispatch(
          updateCouvertures(data.couvertures === null ? "" : data.couvertures)
        );
        dispatch(
          updateStatutProfessionnel(
            data.statutProfessionnel === null ? "" : data.statutProfessionnel
          )
        );
        dispatch(updateFumeur(data.fumeur === null ? "" : data.fumeur));
        dispatch(updateOptin(data.optin === null ? "" : data.optin));
        dispatch(updateStartedSimulation("true"));
        dispatch(updateActualPageIndex(parseInt(data.actualPage)));
      }
    });
  });
}

export default function StartSimulation(props) {
  const dispatch = useDispatch();

  fetch(
    backendUrl + "simulations/emprunteur/startSimulation/?newSimulation=false",
    { credentials: "include", crossDomain: true }
  ).then((resp) => {
    resp.json().then((data) => {
      if (data.statut === "success") {
        if (parseInt(data.actualPage) !== 1 || data.typePret !== "") {
          dispatch(updateTempPageIndex(parseInt(data.actualPage)));
        }

        dispatch(
          updateSessionId(data.sessionId === null ? "" : data.sessionId)
        );
        dispatch(
          updateTimestamp(data.timestamp === null ? "" : data.timestamp)
        );
        dispatch(
          updateMontantEmprunt(
            data.montantEmprunt === null ? "" : data.montantEmprunt
          )
        );
        dispatch(
          updateDureeEmprunt(
            data.dureeEmprunt === null ? "" : data.dureeEmprunt
          )
        );
        dispatch(updateQuotite(data.quotite === null ? "" : data.quotite));
        dispatch(
          updateFirstName(data.firstName === null ? "" : data.firstName)
        );
        dispatch(updateLastName(data.lastName === null ? "" : data.lastName));
        dispatch(updateEmail(data.email === null ? "" : data.email));
        dispatch(updatePhone(data.phone === null ? "" : data.phone));
        dispatch(updatePhoneChecked(data.phoneChecked === null ? "" : data.phoneChecked));
        dispatch(updateDayBorn(data.dayBorn === null ? "" : data.dayBorn));
        dispatch(
          updateMonthBorn(data.monthBorn === null ? "" : data.monthBorn)
        );
        dispatch(updateYearBorn(data.yearBorn === null ? "" : data.yearBorn));
        dispatch(updateTypePret(data.typePret === null ? "" : data.typePret));
        dispatch(
          updateCouvertures(data.couvertures === null ? "" : data.couvertures)
        );
        dispatch(
          updateStatutProfessionnel(
            data.statutProfessionnel === null ? "" : data.statutProfessionnel
          )
        );
        dispatch(updateFumeur(data.fumeur === null ? "" : data.fumeur));
        dispatch(updateOptin(data.optin === null ? "" : data.optin));

        if (parseInt(data.actualPage) === 1 && data.typePret === null) {
          dispatch(updateStartedSimulation("true"));
          dispatch(putTempPageIndex());
        }
      }
    });
  });

  return (
    <>
      <Redirect pageIndex={props.pageIndex} />
      <Modal
        continueSimulation={continueSimulation}
        startNewSimulation={startNewSimulation}
      />
    </>
  );
}
